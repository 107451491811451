var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left"},[_c('BaseHeader',{attrs:{"title":'Editors'}}),(_vm.loading)?_c('BaseLoading',{staticStyle:{"min-height":"400px","height":"90vh"}}):_c('section',[_c('div',{staticClass:"card mt-2 border-0 bg-white shadow"},[_c('div',{staticClass:"card-body text-right d-flex"},[_c('v-text-field',{staticClass:"my-auto",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.$store.state.search),callback:function ($$v) {_vm.$set(_vm.$store.state, "search", $$v)},expression:"$store.state.search"}})],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.editors,"search":_vm.$store.state.search},scopedSlots:_vm._u([{key:"item.names",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('router-link',{attrs:{"to":{
              name: 'WriterProfile',
              params: { id: item.id || '' },
            }}},[_vm._v(_vm._s(item.names || "--"))]):_vm._e()]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.status == '1')?_c('v-chip',{attrs:{"color":"primary","outlined":"","small":""}},[_vm._v("Active")]):_c('v-chip',{attrs:{"color":"error","x-small":""}},[_vm._v("Inactive")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"elevation":"0","x-small":"","fab":"","to":{ name: 'ViewSystemClient', params: { id: item.id || '' } }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }